.portfolio .portfolio-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 50px;
}

@media screen and (max-width: 768px) {
    .portfolio .portfolio-item {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
    }
}

.portfolio .portfolio-item .single-portfolio-content {
    width: 100%;
}

.portfolio .portfolio-item .single-portfolio-content .portfolio-thumb img {
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
}

.portfolio .portfolio-item .single-portfolio-content .portfolio-thumb .portfolio-cat {
    padding: 8px 14px;
    border-radius: 25px;
    background: #2a2e2e;
    color: var(--cm-white);
    line-height: 100%;
    position: absolute;
    left: 10px;
    top: 10px;
    transition: var(--transition);
}

.portfolio .portfolio-item .single-portfolio-content .portfolio-thumb {
    max-width: 100%;
    position: relative;
}

.portfolio .portfolio-item .single-portfolio-content .portfolio-meta .tag {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
}

.portfolio .portfolio-item .single-portfolio-content .portfolio-meta {
    margin-top: 30px;
}

.portfolio .portfolio-item .single-portfolio-content .portfolio-meta .title {
    font-size: 24px;
    font-weight: 700;
    color: var(--heading);
    transition: var(--transition);
}

.portfolio .portfolio-item .single-portfolio-content .portfolio-thumb:hover .portfolio-cat {
    background: var(--cm-primary);
}

.portfolio .portfolio-item .single-portfolio-content .portfolio-thumb .center-description {
    position: absolute;
    width: 100%;
    height: calc(100% - 3px);
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    opacity: 0;
    transition: var(--transition);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;
}

.portfolio .portfolio-item .single-portfolio-content .portfolio-thumb:hover .center-description {
    opacity: 1;
}