body {
    background-color: var(--bg);
    color: var(--body-color);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-wrap: break-word;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--heading);
    margin-top: 0;
    line-height: 1.2;
    margin-bottom: 1rem;
    transition: color .3s ease-out 0s;
    font-size: 32px;
    font-weight: 700;
}

a, button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: var(--body-color);
}

input[type=email], input[type=number], input[type=password], input[type=tel], input[type=text], input[type=url], textarea {
    outline: none;
    background-color: #fff;
    height: 60px;
    width: 100%;
    line-height: 60px;
    font-size: 14px;
    color: var(--cm);
    padding-left: 26px;
    padding-right: 26px;
    border: 1px solid #e0e2e3;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}

ul {
    list-style: none;
}

.btn {
    cursor: pointer;
    height: 70px;
    border-radius: 10px;
    text-align: center;
    color: var(--body-color);
    padding: 15px 35px;
    background: var(--cm-transparent);
    display: flex;
    /*font-family: var(--heading-font);*/
    align-items: center;
    justify-content: center;
    font-weight: 700;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--btn-border);
    z-index: 1;
    font-size: 20px;
    transition: var(--transition);
}

.progress, .progress-stacked {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: var(--bs-secondary-bg);
    --bs-progress-border-radius: var(--bs-border-radius);
    --bs-progress-box-shadow: var(--bs-box-shadow-inset);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}

.theme {
    color: var(--cm-primary) !important;
}

#root {
    display: flex;
    position: relative;
}

@media screen and (max-width: 992px) {
    #root {
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) {
    #root {
        margin: 15px;
    }
}

@media screen and (max-width: 992px) {
    #root {
        margin: 0;
    }
}

.container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media screen and (max-width: 992px) {
    .container {
        flex-wrap: wrap;
    }
}

.container {
    max-width: 1720px;
    width: 100%;
    margin: 15px auto;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    gap: 25px;
}


.author-view {
    padding: 50px 50px 48px;
    text-align: center;
    max-width: 510px;
    max-height: 1100px;
    flex-basis: 45%;
    background: var(--cm);
    border-radius: 10px;
    border: 2px solid var(--cm-border);
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .author-view {
        max-width: 466px;
    }
}

@media screen and (max-width: 1400px) {
    .author-view {
        max-width: 380px;
    }
}

@media screen and (max-width: 1200px) {
    .author-view {
        max-width: 320px;
        max-height: 980px;
        padding: 30px;
    }
}

@media screen and (max-width: 992px) {
    .author-view {
        min-width: 760px;
        max-height: 100%;
        margin: auto;
        padding: 60px;
    }
}

@media screen and (min-width: 769px) {
    .author-view {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

@media screen and (max-width: 768px) {
    .author-view {
        min-width: 100%;
        padding: 50px;
    }
}

@media screen and (max-width: 576px) {
    .author-view {
        min-width: 100%;
        padding: 50px 30px;
    }
}

.author-image {
    position: relative;
    margin-bottom: 40px;
    max-width: 410px;
    margin-left: auto;
    margin-right: auto;
}

.profile-picture-container {
    width: 100%;
    padding-bottom: 100%;
}

.profile-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.author-info {
    margin-bottom: 40px;
}

.author-designation {
    font-size: 16px;
    font-weight: 400;
    color: var(--body-color);
}

.author-hiring {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
    .author-hiring {
        flex-direction: column;
    }
}

@media screen and (max-width: 992px) {
    .author-hiring {
        flex-direction: row;
    }
}

@media screen and (max-width: 576px) {
    .author-hiring {
        flex-direction: column;
    }
}

.author-social-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 50px;
}

.social-links {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--btn-border);
    border-radius: 10px;
    background: transparent;
    transition: var(--transition);
}

.svg {
    color: transparent;
    width: 18px;
    height: 18px;
}

.btn.primary {
    background: var(--cm-primary);
    color: var(--cm-white);
    border-color: var(--cm-primary);
}

.btn {
    border-radius: 10px;
    text-align: center;
    color: var(--body-color);
    padding: 15px 35px;
    background: var(--cm-transparent);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--btn-border);
    z-index: 1;
    font-size: 20px;
    transition: var(--transition);
    text-decoration: none;
}

.author-description {
    max-width: 370px;
    margin: 60px auto 0;
}

.author-description p {
    font-size: 24px;
    line-height: 34px;
}

@media screen and (max-width: 1200px) {
    .author-description p {
        font-size: 16px;
        line-height: 24px;
    }
}

/* tab actions */
.tab-action-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    width: 80px;
    height: max-content;
    padding: 30px 0;
    margin-bottom: 50px;
    background: var(--cm);
    border-radius: 10px;
    border: 2px solid var(--cm-border);
}

@media screen and (max-width: 1200px) {
    .tab-action-item {
        display: none;
    }
}

.tab-action-item ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tab-action-item ul li:not(:last-child) {
    margin-bottom: 40px;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
}

.navbar{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

/*.header-menu {*/
/*    height: 85px;*/
/*    padding: 0 30px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*    justify-content: space-between;*/
/*}*/

/*.header .menu-left {*/
/*    display: flex;*/
/*}*/

/*.menu-left {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    gap: 50px;*/
/*}*/

/*.header .logo-area {*/
/*    margin-right: 50px;*/
/*}*/

.mode {
    background: var(--cm);
    border-radius: 10px;
    border: 2px solid var(--cm-border);
}


@media screen and (max-width: 1200px) {
    .navigation {
        display: none;
    }
}


.header-menu-bar {
    height: 85px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.header-menu-bar .icon {
    cursor: pointer;
}

.header-menu-bar .svg {
    width: 24px;
    height: 21px;
}

.logo-area img {
    width: 50px;
}

.header-menu {
    height: 85px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

@media screen and (max-width: 1200px) {
    .header-menu {
        padding: 0 30px;
    }
}

.mobile-menu {
    min-width: 100vh;
    margin: 0 auto;
    display: none;
}

@media screen and (max-width: 992px) {
    .mobile-menu {
        display: block;
        min-width: 760px;
    }
}

@media screen and (max-width: 768px) {
    .mobile-menu {
        min-width: 100%;
    }
}

.mobile-menu-shadow {
    width: 100%;
    height: 80px;
    background: linear-gradient(180deg, rgb(25, 28, 28), rgb(25, 28, 28, 0));
    position: absolute;
}

.mobile-menu .functionality img {
    width: 24px;
    height: 21px;
}

.sidecontact {
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.sidecontact h6 {
    font-size: 18px;
}

.sidecontact a {
    display: block;
    font-size: 18px;
}

/* sidebar */
.offcanvase {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);
}

.offcanvase.active {
    visibility: visible;
    opacity: 1;
}

.menu-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #565656;
    opacity: .5;
}

.menu-inner {
    max-width: 320px;
    width: 100%;
    background: var(--cm);
    height: 100vh;
    position: fixed;
    left: auto;
    right: 0;
    top: 0;
    z-index: 9999;
    padding: 50px;
    box-shadow: 0 6px 24px 0 rgba(0,0,0,.05), 0 0 0 1px rgba(0,0,0,.08);
    margin-right: -350px;
    transition: var(--transition);
}

.offcanvase.active .menu-inner {
    margin-right: 0;
    visibility: visible;
    overflow-y: auto;
}

.close-icon {
    background: var(--cm-primary);
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 99999;
}

.offcanvase__logo {
    margin-bottom: 30px;
}

.sidemenu .menu {
    margin-bottom: 30px;
}

.sidemenu .menu ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.sidemenu .menu ul li {
    transition: var(--transition);
    position: relative;
    height: 26px;
}

.sidemenu .menu ul li .link, .sidemenu .menu ul li a {
    display: flex;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    align-items: center;
    transition: var(--transition);
    gap: 15px;
}

.sidemenu .menu ul li:after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: "";
    background: var(--cm-primary);
    width: 0;
    height: 1px;
    transition: var(--transition);
}

.sidemenu .menu ul li:hover:after {
    width: 70%;
}

.sidemenu .menu ul li:hover a {
    color: var(--cm-primary);
}

/* content */
.page-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    position: relative;
    min-width: 1075px;
    flex-basis: 60%;
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .page-content {
        min-width: 850px;
    }
}

@media screen and (max-width: 1400px) {
    .page-content {
        min-width: 600px;
        max-width: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .page-content {
        min-width: 640px;
    }
}

@media screen and (max-width: 768px) {
    .page-content {
        min-width: 100%;
    }
}

.content-area {
    position: relative;
}

.content-area:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 50px;
    height: 85px;
    width: 30px;
    background: var(--cm-primary);
    z-index: 9;
}

@media screen and (max-width: 1200px) {
    .content-area:before {
        width: 20px;
    }
}

@media screen and (max-width: 576px) {
    .content-area:before {
        width: 15px;
    }
}

.content-box {
    padding: 80px 110px 105px 105px;
    position: relative;
    max-width: 100%;
    flex-basis: 60%;
    background: var(--cm);
    border-radius: 10px;
    border: 2px solid var(--cm-border);
}

@media screen and (max-width: 1400px) {
    .content-box {
        padding: 60px;
    }
}

@media screen and (max-width: 1200px) {
    .content-box {
        max-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .content-box {
        width: 100%;
        padding: 50px;
    }
}

.content-box .content-circle {
    position: absolute;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    border: 1px solid var(--circle-border);
    right: 60px;
    top: 20px;
    background: var(--circle-bg);
}

@media screen and (max-width: 992px) {
    .content-box .content-circle {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .content-box .content-circle {
        display: none;
    }
}

@media screen and (max-width: 1400px) {
    .content-box .content-circle {
        right: 30px;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.content-circle svg.circle {
    animation: rotate 10s linear infinite;
}

.content-box .content-circle .circle-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 75px;
    width: 75px;
    border: 1px solid var(--circle-border);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cm);
}

.content-box .content-circle svg.circle textPath {
    fill: var(--body-color);
    text-transform: uppercase;
    font-size: 10px;
}

.content-box .content-sub {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.content-box .index .main-title {
    font-size: 80px;
    font-weight: 500;
    letter-spacing: -1px;
    text-transform: capitalize;
    margin-bottom: 35px;
}

@media screen and (max-width: 1400px) {
    .content-box .index .main-title {
        font-size: 50px;
        max-width: 450px;
    }
}

@media screen and (max-width: 440px) {
    .content-box .index .main-title {
        font-size: 35px;
        line-height: 42px;
    }
}

.content-box .index .main-title span {
    color: var(--cm-primary);
}

@media screen and (max-width: 576px) {
    .content-box {
        min-width: 100%;
        padding: 50px 30px;
    }
}

.content-box .contact-zone {
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    gap: 70px;
    flex-wrap: wrap;
}

@media screen and (max-width: 1400px) {
    .content-box .contact-zone {
        gap: 30px;
    }
}

.content-box .contact-zone a {
    display: flex;
    gap: 15px;
    font-size: 24px;
}

.content-img img {
    width: 100%;
}

.content-box .counter {
    margin-top: 110px;
    display: flex;
    align-items: center;
    gap: 150px;
}

@media screen and (max-width: 768px) {
    .content-box .counter {
        gap: 50px;
        flex-wrap: wrap;
        margin-top: 50px;
    }
}

.content-box .counter-single {
    max-width: 170px;
}

.content-box .counter-single .count-item {
    font-size: 52px;
    font-weight: 700;
    margin-bottom: 20px;
    display: block;
    color: var(--heading);
    transition: var(--transition);
}

/* about page */
.page-template .main-title {
    font-size: 52px;
    font-weight: 400;
    max-width: 770px;
    margin-bottom: 50px;
}

@media screen and (max-width: 576px) {
    .page-template .main-title {
        font-size: 35px;
        line-height: 1.3;
    }
}

.page-template .main-title span {
    color: var(--cm-primary);
}

.about-brief {
    margin-top: 30px;
    display: flex;
    gap: 30px;
}

@media screen and (max-width: 768px) {
    .about-brief {
        flex-wrap: wrap;
    }
}

.about-brief .about-left {
    max-width: 310px;
}

.about-brief .about-right {
    max-width: 530px;
}

.about-brief .rating .rating-count a {
    color: var(--cm-primary);
}

.about-brief .rating {
    margin-top: 50px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.about-brief .rating .star-icon {
    height: 50px;
    min-width: 50px;
    background: var(--cm-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--heading);
    font-size: 20px;
}

.about-brief .rating .rating-count h4 {
    margin-bottom: 0;
}

.about-brief p {
    line-height: 26px;
    margin: 0;
}

.about-inf {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    max-width: 500px;
}


@media screen and (max-width: 576px) {
    .about-inf {
        gap: 30px;
        flex-wrap: wrap;
    }
}


.about-info-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.about-info-content .about-info {
    max-width: 220px;
}

.about-info-content .about-info .name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.about-brief .margin {
    margin-top: 30px;
}

.header {
    display: none;
}

@media screen and (max-width: 1200px) and (min-width:992px) {
    .header {
        display: block;
    }
}


@media (max-width: 768px) {
    .hide-in-mobile {
        display: none;
    }
}