:root {
    --bg: #191c1c;
    --heading: #fff;
    --cm: #222525;
    --cm-border: #2a2e2e;
    --body-color: #8b9f9f;
    --cm-primary: #56b887;
    --cm-white: #fff;
    --btn-border: #2a2e2e;
    --circle-border: #2a2e2e;
    --circle-bg: #2a2e2e;
    --border-color-2: #2a2e2e;
    --progress: #383b3b;
    --cm-transparent: transparent;
    --transition: .4s ease;
}